<template>
  <div class="pagecontent-box">
    <div class="yp-position-box">
      <div class="col-left-title">
        <p :title="info.name">{{ info.name?info.name:'暂无' }}</p>
      </div>
      <div class="info-box">
        <div class="yp-info-box applications-content">
          <div class="questions-box">
            <div v-for="(item,idx) in questionsList" :key="idx">

              <ul class="q-text-box" v-if="item.type == 'text'">
                <li class="question_infor">
                  <div class="calc-item">
                    <div class="calc-label-lg">{{ item.question }}</div>
                    <div class="flex-item">
                      <el-input type="text" v-model="item.num"></el-input>
                    </div>
                    <div class="calc-unit-md"></div>
                  </div>
                </li>
              </ul>

              <ul v-if="item.type == 'checkbox'">
                <p class="question_title">{{ item.question }}</p>
                <li v-for="(items,index) in item.options" :key="index">
                  <div class="calc-que">
                    <el-checkbox v-model="items.checked">{{ items.content }}</el-checkbox>
                  </div>
                </li>
              </ul>

              <ul v-if="item.type == 'radio'">
                <li class="question_block">
                  <p class="question_title">{{ item.question }}</p>
                  <div class="question_infor2">
                    <div class="flex-center calc-que">
                      <el-radio-group v-model="item.num">
                        <el-radio :label="items.score" v-for="(items,index) in item.options" :key="index" @change="changeRadio">{{ items.content }}</el-radio>
                      </el-radio-group>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            

          </div>
          <div class="answer_block">
            <div class="question_infor">
              <div class="calc-item">
                <div class="calc-label-md">结果:</div>
                <div class="flex-item" style="display: flex;align-items: center;">
                  <div class="msg_text">{{ calculateValue.value }}</div>
                  <div class="skin-color" @click="getMedicalCalculatorsCalculate">{{ calculateValue.value?'点击更新':'点击查看' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="yp-info-box applications-content">
          <div class="medcalc" v-if="detailsInfo.formula">
            <h3>
              <span class="jiedu-title">计算公式</span>
            </h3>
            <div class="jiedu">
              <p>{{ detailsInfo.formula }}</p>
              <p></p>
              <p></p>
            </div>
          </div>
          <div class="medcalc" v-if="detailsInfo.disease_name">
            <h3>
              <span class="jiedu-title">{{ detailsInfo.disease_name }}</span>
            </h3>
            <div class="jiedu">
              <p>{{ detailsInfo.instructions }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
  import { getMedicalCalculatorsDetails,getMedicalCalculatorsQuestions,getMedicalCalculatorsCalculate } from "@/api/data"
import { json } from "d3";
  export default {
    name: 'cedicalCalculators',
    components: {

    },
    data(){
      return {
        viewHeight:'',
        viewWidth:'',
        sickNess1:[],
        is_view: true,
        main_bg:{},
        tag_pages:'xyzsk',
        name:'',
        tag:'',
        info: null,
        detailsInfo: null, // 详情数据
        questionsList: [],   // 问题列表
        calculateValue: {}
      }
    },
    mounted(){
      let that = this;
    },
    created(){
      let getViewportSize = this.$getViewportSize();
      this.viewHeight = getViewportSize.height;
      this.viewWidth = getViewportSize.width;
      this.main_bg = this.$root.main_bg;  // 背景图
      this.tag = this.$route.query.tag?this.$route.query.tag:'';
      this.info = JSON.parse(this.$route.query.item);
      document.title = this.info.name;
      this.getNewBaseDetail();
      this.getMedicalCalculatorsQuestions();
    },

    methods: {
      changeRadio(e){
        console.log(e)
      },
      setsickNess(){
        this.is_view = false;
        this.$nextTick(() => {
          this.is_view = true
          console.log(this.is_view)
        })
      },
      //获取西医知识库详情页数据
      getNewBaseDetail(){
        let that = this;
        getMedicalCalculatorsDetails({
          id: that.info.id
        }).then( res =>{
          console.log(res)
          this.detailsInfo = res.data.data;
        })
       
      },
      //获取西医知识库详情页数据
      getMedicalCalculatorsQuestions(){
        let that = this;
        getMedicalCalculatorsQuestions({
          id: that.info.id
        }).then( res =>{
          console.log(res)
          if(res.data.code == 0){
            this.questionsList = res.data.data;
          }
        })
       
      },
      // 计算
      getMedicalCalculatorsCalculate(){
        let that = this;
        let questionsList = that.questionsList;
        console.log(questionsList)
        let p = {};
        questionsList.forEach( ele =>{
          if(ele.type == 'text'){
            p[ele.variable_name] = Number(ele.num)
          }
          if(ele.type == 'checkbox'){
            let num = 0;
            ele.options.forEach( o_ele =>{
              if(o_ele.checked){
                num += Number(o_ele.score)
              }
            })
            p[ele.variable_name] = Number(num)
          }
          if(ele.type == 'radio'){
            if(ele.num){
              p[ele.variable_name] = Number(ele.num)
            }
            
          }
        })
        // let inputData = JSON.stringify(p);
        
        getMedicalCalculatorsCalculate({
          id: that.info.id,
          ...p
        }).then( res =>{
          console.log(res)
          if(res.data.code == 0){
            that.calculateValue = res.data.data;
          }else{
            this.$message.error({
              message: res.data.msg
            });
          }
        })
       
      },
    },
  }
</script>
<style lang="scss" scoped>
  li{
    list-style: none;
  }
  /* ==============  滚动条样式   ==================== */
  ::-webkit-scrollbar { 
    width:8px; 
    height:10px; 
    background-color:#dfdbdb; 
  }
  /* 滚动条上的滚动滑块. */
  ::-webkit-scrollbar-thumb { 
    background-color:#2786D9; 
    border-radius: 50px;
  }
  /* ==============  滚动条样式   ==================== */
  .pagecontent-box{
    height: auto;
    background: #FFFFFF;
    padding: 12px;
  }

.yp-position-box {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  // transform: translate(-50%, -50%);
  padding: 0 10px 10px;
  display: flex;
  flex-direction: column;
}
.info-box{
  flex: 1;
  width: 880px;
  height: 100%;
  padding-top: 16px;
  margin: 0 auto;
}
.yp-info-box {
  width: 100%;
  height: auto;
  padding: 0 10px 30px 10px;
  font-size: 15px;
  text-align: left;
  position: relative;
}
.col-left-title {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px dashed rgb(126, 126, 126);
}
.col-left-title>a{
  width: auto;
}
.col-left-title>p{
  flex: 1;
  text-overflow: ellipsis;
  display: box;
  box-orient: vertical;
  line-clamp: 2;
  text-align: center;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  line-height: 35px;
  color: #191919;
  cursor: pointer;
}
.questions-box{
  width: 100%;
  li{
    .calc-que{
      font-size: 16px;
      word-break: break-all;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.3);
      margin-bottom: 5px;
    }
  }
  .q-text-box{
    
    .calc-label-lg{
      width: 180px;
      font-size: 14px;
    }
    .calc-unit-md {
      width: 50px;
      font-size: 14px;
      margin: 0 0 0 10px;
    }
  }
  /deep/ .el-checkbox{
    padding: 10px 0;
    width: 100%;
    padding-left: 30px;
  }
  /deep/ .el-checkbox__label {
    font-size: 16px;
  }
  /deep/ .el-radio-group{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  /deep/ .el-radio{
    width: 50%;
    padding: 10px 20px;
    margin: 0;
  }
  /deep/ .el-radio__label{
    font-size: 16px;
  }
  .question_title {
    background-color: #edf8fc;
    font-weight: bold;
    padding: 10px 20px;
    user-select: none;
    border-radius: 5px;
    font-size: 14px;
  }
  .question_infor2{
    padding: 10px 0 10px 10px;
  }
}
.answer_block {
  margin-top: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  .skin-color {
    color: #1d78b6;
    cursor: pointer;
  }
  .msg_text{
    color: #ff0000;
    padding-right: 20px;
  }
}
.question_infor {
  padding: 10px 16px;
}
.calc-item {
  font-size: 16px;
  line-height: 24px;
  word-break: break-all;
  display: flex;
  align-items: center;
}
.calc-label-md {
  width: 100px;
}
.flex-center{
  display: flex;
  align-items: center;
}
.flex-item{
  flex: 1;
}
.medcalc{
  h3 {
    background-color: #1d78b6;
    width: 100%;
    height: 36px;
    margin-top: 20px;
    color: #fff;
    line-height: 36px;
    border-radius: 5px;
  }
  .jiedu-title{
    font-size: 18px;
    margin-left: 10px;
  }
  .jiedu {
    font-size: 14px;
    line-height: 22px;
    padding: 6px 6px 0 6px;
    text-align: justify;
    p {
      margin-top: 10px;
      color: #595959;
    }
  }
}
@media only screen and (max-width: 758px){
  .pagecontent-box{
    padding: 0;
    margin: 0;
  }
  .yp-position-box{
    padding: 0;
    margin: 0;
  }
  .col-left-title > p{
    text-align: left;
  }
  .yp-position-nbox{
    padding: 0;
    height: auto;
  }
  .yp-info-box{
    padding: 0;
  }
}

</style>
